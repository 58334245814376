<template>
  <div class="dayReportView">
    <van-sticky>
      <van-nav-bar :title="$t('日结报表')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-cell
      :value="currentDate"
      icon="cluster"
      :title="$t('总收益') + '円' + data.today_total_profit"
      class="mt15"
    ></van-cell>
    <div class="total">
      <ul>
        <div class="databox">
          <li>
            <em>{{ $t('我完成的任务') }}({{ $t('单') }})</em>
            <span v-text="data.today_over_task">0</span>
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('我的任务收益') }}(円)</em>
            <span v-text="data.today_task_profit">0</span>
          </li>
        </div>
      </ul>
      <ul>
        <div class="databox">
          <li>
            <em>{{ $t('下级完成的任务') }}({{ $t('单') }})</em>
            <span v-text="data.today_team_task_num">0</span>
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('下级任务收益') }}(円)</em>
            <span v-text="data.today_team_task_profit">0</span>
          </li>
        </div>
      </ul>
    </div>
    <van-cell :value="$t('最近30天')" icon="cluster" :title="$t('日结报表')" class="mt15"></van-cell>
    <table width="100%">
      <thead>
        <tr>
          <th>{{ $t('数量') }}</th>
          <th>{{ $t('任务') }}</th>
          <th>{{ $t('下级') }}</th>
          <th>{{ $t('消费') }}</th>
          <th>{{ $t('日期') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(val, index) in data.dayReport" :key="index">
          <td v-text="val.number">0</td>
          <td>
            <em v-text="val.task">0</em>
          </td>
          <td v-text="val.tjj">0</td>
          <td>
            <em v-text="val.team">0</em>
          </td>
          <td v-text="val.date"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { formatDate } from "@/utils/func";
import { getDayReport } from "@/api/task";
export default {
  name: "DayReport",
  data() {
    return {
      currentDate: this.$func.formatDate(new Date()),
      data: []
    };
  },
  created() {
    this.GetDayReport();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //获取
    GetDayReport() {
      getDayReport({})
        .then(response => {
          this.data = response.result;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.dayReportView
  .van-cell
    align-items: center
    background-color: #151d31
    .van-cell__title
      color: #4087f1
      font-weight: 600
    .van-cell__left-icon
      color: #4087f1
    .van-cell__value
      color: #bbb
  .total
    margin-bottom: 0.3rem
    color: #4087f1
    font-weight: 600
    margin-top: 1px
    ul
      display: flex
      text-align: center
      font-size: 0.42rem
      line-height: 1
      margin-bottom: 1px
      .databox:last-child
        padding: 0
      .databox
        flex: 1
        padding-right: 1px
        li
          color: #4087f1
          background-color: #151d31
          flex-direction: column
          box-sizing: border-box
          padding: 0.5rem 0.2rem
          height: 100%
          em
            display: block
            line-height: 1
            margin-bottom: 0.3rem
            color: #bbb
            font-size: 0.4rem
            font-weight: 400
  table
    border-collapse: collapse
    border-spacing: 0
    background-color: #151d31
    text-align: center
    margin-bottom: 0.6rem
    font-weight: 500
    tr
      th
        padding: 0.375rem 0.3125rem
        background-color: #0e1526
        color: #bbb
      td
        border: 0.03rem solid #0e1526
        padding: 0.3rem 0.15rem
        color: #fff
        em
          color: #4087f1
      td:first-child, td:last-child
        border-left: 0
</style>
